.paid-plans-container {
  position: relative;
  height: 100vh; /* Full viewport height */
  overflow: auto; /* Hide overflow content beyond viewport */
}

  .image-background {
    height: 50%; /* Adjust the height as needed */
    background: url('../../img/pricing_bg.png') center/cover no-repeat !important;
    z-index: 0; /* Place below other elements */
  }

  .color-background {
    height: 50%; /* Adjust the height as needed */
    background-color: #F1F5FE; /* Background color for the second part */
    z-index: 0; /* Place below other elements */
  }

  .absolute-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    /* text-align: center; */
    /* color: white; Adjust the text color based on your design */
  }

.content-text {
  margin-top: 20px; /* Adjust as needed */
  text-align: center;
}

.text-1{
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.text-2{
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.dropdown-1 {
  width: 150px;
}


.toggle-button-div{
width: 200px;
background-color: #ffffff;
border-radius: 5px;
height: 34px;
width: 194px;
/* align-items: center; */
/* justify-content: center; */
}

.active-plan-button{
  font-size: 12px;
  border-radius: 5px;
  color: #FFFFFF;
  height: 32px;
  background-color: #693393;
  width: 94px;
  margin-right: 1px;
  margin-left: 1px;
  font-weight: 500;
  transition: background-color 0.5s ease;
}

.active-plan-button:focus{
  outline: none;
}

.inactive-plan-button{
  font-size: 12px;
  color: #693393;
  width: 94px;
  height: 32px;
  transition: color 0.5s ease;
}

.inactive-plan-button:focus{
  outline: none;
}

/* Add this to your CSS file, or create a new CSS file if needed */
.sticky-container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000; /* Adjust the z-index as needed to ensure it appears above other elements */
}


.paid-plans-container {
  height: auto;
  overflow: visible;
}

/* Ensure the elements are stacked vertically on smaller screens */
@media (max-width: 480px) {
  .paid-plans-container {
    height: auto;
    overflow: visible;
  }

  .image-background .dropdown-toggle-container {
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Add some space between the elements */
  }

  .cards-row {
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Add some space between the cards */
  }

  .cards-row > div {
    width: 90%; /* Ensure cards take most of the width */
  }

  .text-1, .text-2{
    color: #000000;
  }

  .image-background{
    background: none !important;
  }

  .custom-css, .custom-css-multiselect-dd{
    border: 0.5px solid gray;
  }
}

