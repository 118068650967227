.card-div {
  width: calc(25% - 1rem);
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  box-shadow: 1.6px 1.6px 15.99995px 0 #00000026;
  height: 88px;
  width: 218px;
}

.card-icon{
  width: 42px;
  height: 42px;
}

.card-title{
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  font-weight: 400;
}

.card-data{
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}

.card-internal-wrapper{
  border-radius: 0.5rem;
}


/* Remove margin-right for the last card in a row */
.card-div:nth-child(5n) {
  margin-right: 0;
  
}

@media (min-width: 360px) and (max-width: 480px) {

  
  .card-div {
    width: calc(50% - 0.5rem);
    border-radius: 0.5rem;
  box-shadow: 1.6px 1.6px 15.99995px 0 #00000026;
  height: 61px;
  width: 161px;
  }

  .card-internal-wrapper{
    border-radius: 0.4rem;
  }

  /* Remove margin-right for the last card in a row */
  .card-div:nth-child(2n) {
    margin-right: 0;
    border-radius: 0.5rem;
  box-shadow: 1.6px 1.6px 15.99995px 0 #00000026;
  }

  .card-icon{
    width: 30px;
    height: 30px;
  }

  .card-title{
    font-size: 10px;
    font-weight: 400;
    color: #ffffff;
    font-weight: 400;
  }

  .card-data{
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }


}