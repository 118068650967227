/* public/styles.css */
body.with-background {
  /* background-image: url('../../img/bg_3.png') !important; */
  /* background-size: cover !important; */
  /* Additional background properties (e.g., background-repeat, background-position) can be added here */
}

.navbar-logo-text{
text-decoration-line: none;
}

.side-text-1, .side-text-2, .side-text-3, .side-text-4{
  font-size: 52px;
  font-weight: 600;
}

.side-text-1{
  color: #3F3589;
  margin-bottom: 20px;
}

.side-text-2{
  color: #3F3589;
  margin-bottom: 20px;
}

.side-text-3{
  color: #3F3589;
  margin-bottom: 20px;
}

.side-text-4{
  color: #3F3589;
}

.register-input-field{
  border: none;
  font-size: 14px;
  color: #454545;
  border-bottom: 1px solid #d8d8d8;
  padding: 0px;
}

.register-input-field::placeholder{
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
}

.register-input-field:focus {
  border: none;
  outline: none; /* Remove outline on focus */
  border-bottom: 1px solid #3490dc; /* Change the focused border color */
}

.color-logo-register{
  height: 44px;
  width: 140px;
  padding-top: 4px;
}

.form-heading{
  font-size: 30px;
  color: #693293;
  margin-bottom: 4px;
  font-weight: 400;
}

.sub-heading{
  margin-bottom: 20px;
  font-size: 11px;
  color: #797676;
}

.input-icon-register{
  width: 14px;
  height: 14px;
}

.register-button{
  background-color: #693293;
  font-weight: 400;
  color: #FFF;
}
.register-button:hover, 
.register-button:focus{
  background-color: #F4E869;
  color: #000;
}

.login-link, .terms-and-condition-link{
  font-weight: 500;
  color: #693293;
}

.login-link:hover, .terms-and-condition-link:hover{
  color: #5135EE;
}

/* global.css or component-specific CSS */
.password-input {
  border: none;
  border-bottom: 1px solid #d8d8d8;
  /* padding-bottom: 0px; */
  padding: 0px;
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
}

/* Adjust the placeholder text size */
.password-input::placeholder {
  font-size: 12px; /* Set your desired placeholder text size */
  font-family: 'Montserrat', sans-serif;
}

.password-input:focus {
  border: none;
  outline: none; /* Remove outline on focus */
  border-bottom: 1px solid #3490dc; /* Change the focused border color */
}

.register_page_image{
width: 700px;
height: 600px;
}

.terms-and-condition{
font-size: 10px;
}

.already-account{
font-size: 12px;
font-weight: 400;
}

@media screen and (max-width: 480px) {
  /* Hide right side */
  .right-side-login {
    display: none;
  }

  /* Adjust left side to take up full width */
  .flex-shrink-0.left-area {
    width: 100%;
    padding: 20px 0px; /* Add some padding for better spacing */
  }

  .div-2{
    height: 100vh;
    padding-bottom: 100px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
