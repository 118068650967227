.upgrade-icon{
  height: 36px;
  width: 36px;
}

.upgrade-plan-heading{
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}

.upgrade-plan-sub-text{
  font-size: 12px;
  font-weight: 400;
  color: #797878;
}

.upgrade-plan-cancel-button{
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}


.upgrade-plan-upgrade-button{
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #693293;
}

.upgrade-plan-upgrade-button:hover, .upgrade-plan-upgrade-button:focus{
  background-color: #F4E869;
  color: #000000;
}

.mobile-view-upgrade-to-pro-modal{
  align-items: flex-end;
}

@media (min-width: 360px) and (max-width: 480px){
  .mobile-view-upgrade-to-pro-modal{
    align-items: center;
  }

  .upgrade-plan-upgrade-button{
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #693293;
    margin-bottom: 5px;
  }
}