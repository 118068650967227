.page {
  background-color: #f1f5fe;
}

.invalid-button-link {
  font-weight: 400;
  color: #ffffff;
  background-color: #693293;
}

.invalid-button-link:active {
  background-color: #f4e869;
}

.invalid-button-link:hover,
.invalid-button-link:focus {
  color: #ffffff;
}

@media (max-width: 1279px) {
  .logo-style {
    height: 44px;
    width: 144px;
  }
  .img-style {
    height: 71px;
    width: 90px;
  }
  .invalid-link-heading {
    font-size: 24px;
    color: #000000;
  }

  .invaild-link-note {
    font-size: 12px;
    color: #303030;
    text-decoration-line: none;
  }
  .invalid-button-link-1 {
    border-radius: 5px;
    font-size: 14px;
    width: 289px;
    height: 34px;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1535px) {
  .logo-style {
    height: 44px;
    width: 144px;
  }
  .img-style {
    height: 71px;
    width: 90px;
  }
  .invalid-link-heading {
    font-size: 24px;
    color: #000000;
  }

  .invaild-link-note {
    font-size: 12px;
    color: #303030;
  }
  .invalid-button-link-1 {
    border-radius: 5px;
    font-size: 14px;
    width: 289px;
    height: 34px;
    padding: 8px 16px;
  }
}

/* Styles for desktop screens */
@media (min-width: 1536px) {
  .logo-style {
    height: 66px;
    width: 210px;
  }
  .img-style {
    height: 106px;
    width: 135px;
  }
  .invalid-link-heading {
    font-size: 36px;
    color: #000000;
  }

  .invaild-link-note {
    font-size: 14px;
    color: #303030;
  }
  .invalid-button-link-1 {
    border-radius: 8px;
    font-size: 16px;
    width: 310px;
    height: 34px;
    padding: 22px 40px;
  }
}

@media (min-width: 360px) and (max-width: 480px){
  .img-style{
    width: 77px;
    height: 61px;
    margin-bottom: 10px;
  }

  .invalid-link-heading{
    margin-bottom: 10px;
  }

  .invaild-link-note{
    margin-bottom: 20px;
  }
}
