.analytics-page-1{
  background-color: #F1F5FE;
  margin-left: 256px;
}

.button-bg-gradient{
  background: linear-gradient(90deg, #4D0694 0%, #33C0ED 98.55%);
}

.prev-button, .next-button{
  font-size: 11px;
  letter-spacing: .35em;
  font-weight: 500;
}

.prev-button:disabled, .next-button:disabled{
  color: gray;
  cursor: not-allowed;
}

.page-count{
  font-size: 14px;
  letter-spacing: .25em;
  font-weight: 500;
}

/* Add this to your stylesheet or in the <style> tag within your component */
.note {
  background-color: #f8d7da; /* Red background color (you can choose your own color) */
  color: #721c24; /* Text color */
  padding: 2px 5px; /* Padding for spacing */
  margin-bottom: 15px; /* Bottom margin for separation from other content */
  border: 1px solid #f5c6cb; /* Border color */
  border-radius: 5px; /* Border radius for rounded corners */
  width: auto;
  font-size: 11px;
}

.report-card-component-wrapper{
padding: 16px;
}



@media (min-width: 360px) and (max-width: 480px) {
  .sidebar-hidden{
    display: none;
  }

  .analytics-page-1{
    background-color: #F1F5FE;
    margin-left: 0px;
  }

  .report-card-component-wrapper{
    padding: 10px;
  }
}


