.page {
  background-color: #f1f5fe;
  /* background-color: #add169; */
}
.page404-button-link {
  font-weight: 500;
  color: #ffffff;
  background-color: #693293;
}

.page404-button-link:active {
  background-color: #f4e869;
}

.page404-button-link:hover,
.page404-button-link:focus {
  color: #ffffff;
}

.main-text {
  /* font-size: 24px; */
  color: #000000;
}

.sub-text {
  /* font-size: 12px; */
  color: #303030;
}

@media (max-width: 1279px) {
  .logo-style-1 {
    width: 144px;
    height: 40px;
  }
  .img-style-1 {
    width: 145px;
    height: 82px;
  }
  .page-404-heading {
    font-size: 24px;
    color: #000000;
  }

  .page-404-note {
    font-size: 12px;
    color: #303030;
  }
  .page404-button-link-1 {
    border-radius: 5px;
    font-size: 14px;
    width: 289px;
    height: 34px;
    padding: 8px 16px;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1535px) {
  .logo-style-1 {
    width: 144px;
    height: 40px;
  }
  .img-style-1 {
    width: 145px;
    height: 82px;
  }
  .page-404-heading {
    font-size: 24px;
    color: #000000;
  }

  .page-404-note {
    font-size: 12px;
    color: #303030;
  }
  .page404-button-link-1 {
    border-radius: 5px;
    font-size: 14px;
    width: 289px;
    height: 34px;
    padding: 8px 16px;
  }
}

/* Styles for desktop screens */
@media (min-width: 1536px) {
  .logo-style-1 {
    width: 210px;
    height: 66px;
  }
  .img-style-1 {
    width: 217px;
    height: 123px;
  }
  .page-404-heading {
    font-size: 36px;
    color: #000000;
  }

  .page-404-note {
    font-size: 18px;
    color: #303030;
  }
  .page404-button-link-1 {
    border-radius: 8px;
    font-size: 18px;
    width: 434px;
    height: 34px;
    padding: 22px 40px;
  }
}

@media (min-width: 360px) and (max-width: 480px){
  
  .page-404-note{
    margin-bottom: 20px;
  }

}