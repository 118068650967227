body.with-background {
  background-color: #F1F5FE;
  background-size: cover !important;
  /* Additional background properties (e.g., background-repeat, background-position) can be added here */
}

.user-profile-main-content-div {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.profile-back-home {
  color: #693293;
  font-size: 13px;
  font-weight: 500;
}

.profile-back-home:hover {
  color: #693293;
  text-decoration: none;
}

.profile-pic-placeholder {
  width: 150px;
  height: 150px;
  margin-bottom: 23px;
}

.update-profile-pic {
  font-size: 12px;
  font-weight: 400;
  color: #0585FB;
  text-decoration: underline;
  cursor: pointer;
}

.update-profile-pic:hover {
  color: #4B4389;
}

.input-fields-div {
  width: 100%;
}

.profile-input, .profile-input-email, .profile-password {
  width: 100%;
}

.profile-button-save {
  background-color: #693293;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
}

.profile-button-save:hover, .profile-button-save:focus {
  background-color: #f4e869;
  color: #000000;
}

.profile-button-cancel {
  background-color: #EFEFEF;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.profile-button-cancel:hover, .profile-button-cancel:focus {
  background-color: #E6E6E6;
  color: #000000;
}

/* MODAL */

.profile-modal-open {
  display: flex; /* or other appropriate styles to show the modal */
}

.profile-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.profile-modal {
  background: white;
  padding: 20px;
  width: 100%;
  max-width: 744px;
}

.profile-upload-icon {
  font-size: 50px;
  margin-top: 10px;
  color: #693293;
}

.profile-upload-text {
  margin-top: 20px;
  color: #828282;
  font-size: 20px;
}

.profile-select-button {
  background-color: #693293;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 20px;
  border: none;
}

#file-upload-button {
  border: none;
}

.profile-upload-button {
  background-color: #693293;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 40px;
}

.profile-upload-button:disabled {
  background-color: #828282;
  cursor: not-allowed;
}

.profile-select-button:hover {
  background-color: #f4e869;
  color: #000000;
}

.profile-upload-button:hover:not(:disabled), .profile-upload-button:focus:not(:disabled) {
  background-color: #f4e869;
  color: #000000;
}

.profile-modal-close-icon {
  font-size: xx-large;
  color: #D77E7E;
}

.profile-modal-close-icon:hover {
  cursor: pointer;
}

.profile-password {
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
}

.profile-password::placeholder {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.my-profile-box{
  padding: 20px;
}

.my-profile-personal-details-cards{
  padding: 12px;
}

.my-profile-edit-details-button:hover, .my-profile-edit-details-button:focus{
background-color: #f4e869;
color: #000000;
}

/* Media Queries */

@media (min-width: 360px) and (max-width: 480px){
  .my-profile-box {
    padding: 5px;
    width: 350px;
  }

  .my-profile-back-home {
    font-size: 12px;
  }

  .profile-pic-placeholder {
    width: 120px;
    height: 120px;
  }

  .my-profile-details {
    margin-left: 0 !important;
  }


  .my-profile-header h2 {
    white-space: nowrap;
    font-size: 14px;
  }

  .my-profile-information h2{
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    font-size: 14px;
  }

  .my-profile-edit-details-button {
    width: 100%;
  }

  .my-profile-personal-details-cards{
    width: 340px;
    padding: 5px;
  }

  .profile-details-wrapper{
    align-items: center;
    /* justify-content: center; */
  }

  .my-profile-personal-details-cards{
    padding: 5px;
    justify-content: center;
    align-items: center;
  }

  .my-profile-button-wrapper{
    justify-content: center;
  }

  .user-profile-main-content-div{
    width: 350px;
    margin:auto;
    height: auto;
    margin-top: 10px;

  }

  .input-fields-div{
    padding: 10px;
  }

  .profile-modal{
    padding: 10px;
    height: 350px;

  }

  .profile-select-button{
    width: 100%;
  }
}

@media (max-width: 768px) {
  .profile-modal {
    width: 90%;
  }

  .profile-upload-button,
  .profile-button-save,
  .profile-button-cancel {
    width: 100%;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}