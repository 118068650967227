.checkyouremail-page {
  background-color: #f1f5fe;
}

.login-button {
  background-color: #693293;
  font-weight: 400;
  color: #fff;
}

.login-button:hover,
.login-button:focus {
  background-color: #f4e869;
  color: #000;
}

.checkyouremail-button-link {
  font-weight: 500;
  color: #ffffff;
  background-color: #693293;
}

.checkyouremail-button-link:active {
  background-color: #f4e869;
}
.checkyouremail-button-link:hover,
.checkyouremail-button-link:focus {
  color: #ffffff;
}

@media (max-width: 1279px) {
  .logo-style-1 {
    width: 144px;
    height: 40px;
  }
  .email-img-style {
    width: 103px;
    height: 83px;
  }
  .main-text-1 {
    font-size: 24px;
    color: #000000;
  }

  .sub-text-1 {
    font-size: 12px;
    color: #303030;
  }
  .checkyouremail-button-link-1 {
    font-size: 14px;
    width: 270px;
    border-radius: 8px;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1919px) {
  .logo-style-1 {
    width: 144px;
    height: 40px;
  }
  .email-img-style {
    width: 103px;
    height: 83px;
  }
  .main-text-1 {
    font-size: 24px;
    color: #000000;
  }

  .sub-text-1 {
    font-size: 12px;
    color: #303030;
  }
  .checkyouremail-button-link-1 {
    font-size: 14px;
    width: 270px;
    border-radius: 8px;
  }
}

/* Styles for desktop screens */
@media (min-width: 1920px) {
  .logo-style-1 {
    width: 210px;
    height: 66px;
  }
  .email-img-style {
    width: 154px;
    height: 124px;
  }
  .main-text-1 {
    font-size: 36px;
    color: #000000;
  }

  .sub-text-1 {
    font-size: 18px;
    color: #303030;
  }
  .checkyouremail-button-link-1 {
    font-size: 18px;
    width: 434px;
    height: 54px;
    border-radius: 8px;
  }
  .checkyouremail-box-size {
    width: 433.5px;
    height: 454.5px;
  }
}


@media (min-width: 360px) and (max-width: 480px){
  
}
