.page {
  background-color: #f1f5fe;
}

.access-denied-login-button {
  font-weight: 500;
  font-size: 14px;
  color: #693293;
  border-radius: 5px;
  width: 100%;
  padding: 8px 16px;

  border-width: 1.5px;
  border-color: #693293;
}
.access-denied-login-button:hover,
.access-denied-login-button:focus {
  background-color: #f4e869;
  color: #303030;
  border-color: #f4e869;
}
.access-denied-register-button {
  background-color: #693293;

  font-weight: 500;
  font-size: 14px;

  color: #ffffff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 16px;
}

.access-denied-register-button:hover,
.access-denied-register-button:focus {
  background-color: #f4e869;
  color: #303030;
  border-color: #f4e869;
}

.access-denied-main-wrapper{
  padding: 20px;
}

@media (max-width: 1279px) {
  .logo-style-1 {
    width: 144px;
    height: 40px;
  }
  .img-style-1 {
    width: 90px;
    height: 71px;
  }
  .access-denied-heading {
    font-size: 24px;
    color: #000000;
  }

  .access-denied-note {
    font-size: 13px;
    color: #303030;
  }
  .access-denied-login-button-1 {
    font-size: 14px;
    border-radius: 5px;
  }
  .access-denied-register-button-1 {
    font-size: 14px;
    border-radius: 5px;
    margin-top: 25px;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1535px) {
  .logo-style-1 {
    width: 144px;
    height: 40px;
  }
  .img-style-1 {
    width: 90px;
    height: 71px;
  }
  .access-denied-heading {
    font-size: 24px;
    color: #000000;
  }

  .access-denied-note {
    font-size: 13px;
    color: #303030;
  }
  .access-denied-login-button-1 {
    font-size: 14px;
    border-radius: 5px;
  }
  .access-denied-register-button-1 {
    font-size: 14px;
    border-radius: 5px;
    margin-top: 25px;
  }
}

/* Styles for desktop screens */
@media (min-width: 1536px) {
  .logo-style-1 {
    width: 210px;
    height: 66px;
  }
  .img-style-1 {
    width: 160px;
    height: 150px;
  }

  .access-denied-heading {
    font-size: 36px;
    color: #000000;
  }

  .access-denied-note {
    font-size: 18px;
    color: #303030;
  }
  .access-denied-login-button-1 {
    font-size: 18px;
    border-radius: 8px;
  }
  .access-denied-register-button-1 {
    font-size: 18px;
    border-radius: 8px;
    margin-top: 28px;
  }
}

@media (min-width: 360px) and (max-width: 480px){
.access-denied-main-wrapper{
  padding: 10px;
}

.img-style-1{
  margin-bottom: 20px;
}
.access-denied-heading{
  margin-bottom: 10px;
}

.access-denied-note{
  margin-bottom: 20px;
}
}
