.otp-page {
  background-color: #f1f5fe;
}

.box-size{
width: 394px;
height: 472px;
}

.session-button-link {
  font-weight: 500;
  color: #ffffff;
  background-color: #693293;
}

.session-button-link:active {
  background-color: #f4e869;
}
.session-button-link:hover,
.session-button-link:focus {
  color: #ffffff;
}

@media (max-width: 1279px) {
  .session-erpire-icon {
    width: 91px;
    height: 81px;
  }
  .session-primary-text {
    font-size: 24px;
    color: #ff0000;
    text-align: center;
  }
  .session-secondary-text {
    font-size: 14px;
    color: #303030;
    text-align: center;
  }
  .session-button-link-1 {
    border-radius: 8px;
    font-size: 14px;
    width: 270px;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1535px) {
  .session-erpire-icon {
    width: 91px;
    height: 81px;
  }
  .session-primary-text {
    font-size: 24px;
    color: #ff0000;
    text-align: center;
  }
  .session-secondary-text {
    font-size: 14px;
    color: #303030;
    text-align: center;
  }
  .session-button-link-1 {
    border-radius: 8px;
    font-size: 14px;
    width: 270px;
  }
}


@media (min-width: 360px) and (max-width: 480px){
  .box-size {
    width: 300px;
    padding: 20px;
    height: 350px;
  }

  .session-erpire-icon{
    width: 81px;
    height: 71px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .session-primary-text{
    margin-bottom: 10px;
  }
  .session-secondary-text{
margin-bottom: 20px;
  }
}
