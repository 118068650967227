.table-header{
font-size: 14px;
font-weight: 500;
color: #000000;
background-color: #ECE3FF !important;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
padding: 10px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #ECE3FF !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.download-button {
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  background-color: #693293;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}


.download-button:hover {
  /* background-color: #45a049; */
  font-size: 14px;
  color: #f4e869;
  transition: 0.2s;
  /* transition: cubic-bezier(0.23, 1, 0.320, 1); */
}

.download-button:focus{
outline: none;
}

.download-button:disabled{
  background-color: darkgrey;
  color: #000000;
  pointer-events: none;
}

.table-column-head{
  font-size: 11px;
  font-weight: 500;
  color: #000000;
  background-color: #FFFFFF;
}

.table-data-row{
  font-size: 11px;
  font-weight: 400;
  color: #000000;
}

.price-hiked{
background-color: #D4EED5;
color: #26AC2C;
padding: 2px 8px;
width: fit-content;
border-radius: 4px;
}

.price-dropped{
  background-color: #F6DAD5;
  color: #FB0D0D;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 4px;
}

.no-price-change{
  background-color: #FFF2DA;
  color: #DD940C;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 4px;
}

.new-arrival{
  background-color: #E2DDFF;
  color: #5135EE;
  padding: 2px 8px;
  border-radius: 4px;
  width: fit-content;
}

.table-data-row{
  border-bottom: 1px solid #e5e7eb;
}

.details-button{
  color: #5135EE;
}

@media (min-width: 360px) and (max-width: 480px){
  .table-top-wrapper{
    margin: 0px 10px;
    min-width: fit-content;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #ECE3FF !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* min-width: fit-content; */
  }

  .mobile-view-table-wrapper{
    min-width: max-content;
  }

  .header-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
}