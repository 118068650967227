.page {
  background-color: #f1f5fe;
}

.password-register-input-field {
  background-color: #f1f5fe;
  border: none;
  color: #454545;
  border-bottom: 1px solid #d8d8d8;
  padding: 0px;
  font-size: 14px;
}

.password-register-input-field::placeholder, .password-register-input-field-1{
  font-size: 12px;
}

.password-register-input-field:focus {
  border: none;
  outline: none; /* Remove outline on focus */
  border-bottom: 1px solid #797676; /* Change the focused border color */
}

.password-register-button {
  background-color: #693293;
  color: #fff;
  font-weight: 400;
}
.password-register-button:hover,
.password-register-button:focus {
  background-color: #f4e869;
  color: #303030;
}

.password-back-to-login-link {
  font-weight: 500;
  color: #303030;
}

.password-back-to-login-link:hover {
  text-decoration: none;
  color: #5135EE;
}

.password-resend-link {
  color: #693293;
}
.password-resend-link:hover {
  color: #5135ee;
}

@media (max-width: 1279px) {
  .logo-style {
    width: 144px;
    height: 40px;
  }
  .forgot-password-img {
    width: 94px;
    height: 98px;
  }
  .checkyouremail-img {
    width: 103px;
    height: 83px;
  }
  .forgot-password-heading {
    font-size: 24px;
    color: #000000;
  }

  .forgot-password-note {
    font-size: 12px;
    color: #303030;
  }
  .password-register-input-field-1 {
    font-size: 12px;
  }
  .password-register-button-1 {
    font-size: 12px;
  }
  .password-back-to-login-link-1 {
    font-size: 12px;
  }
  /* .forgot-password-arrow {
    margin-top: 4px;
  } */
  .password-login-link {
    font-size: 12px;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1919px) {
  .logo-style {
    width: 144px;
    height: 40px;
  }
  .forgot-password-img {
    width: 94px;
    height: 98px;
  }

  .checkyouremail-img {
    width: 103px;
    height: 83px;
  }

  .forgot-password-heading {
    font-size: 24px;
    color: #000000;
  }

  .forgot-password-note {
    font-size: 12px;
    color: #303030;
  }
  .password-register-input-field-1 {
    font-size: 14px;
  }
  .password-register-button-1 {
    font-size: 12px;
  }
  .password-back-to-login-link-1 {
    font-size: 12px;
  }
  /* .forgot-password-arrow {
    margin-top: 3px;
  } */
  .password-login-link {
    font-size: 12px;
  }
}

/* Styles for desktop screens */
@media (min-width: 1920px) {
  .logo-style {
    width: 210px;
    height: 66px;
  }
  .forgot-password-img {
    width: 141px;
    height: 147px;
  }
  .checkyouremail-img {
    width: 154px;
    height: 124px;
  }

  .forgot-password-heading {
    font-size: 36px;
    color: #000000;
  }

  .forgot-password-note {
    font-size: 18px;
    color: #303030;
  }
  .password-register-input-field-1 {
    font-size: 18px;
  }
  .password-register-button-1 {
    font-size: 18px;
  }
  .password-back-to-login-link-1 {
    font-size: 18px;
  }
  /* .forgot-password-arrow {
    margin-top: 4px;
  } */
  .password-login-link {
    font-size: 18px;
  }
  .password-login-text {
    font-size: 14px;
  }
}

@media (min-width: 360px) and (max-width: 480px){
  .forgot-password-img{
    width: 64px;
    height: 68px;
    margin-bottom: 20px;
  }

  .forgot-password-heading{
    margin-bottom: 10px;
  }

  .forgot-password-note{
    margin-bottom: 20px;
  }

  .password-register-input-field{
    margin-bottom: 20px;
    font-size: 16px;
  }

  .password-register-input-field::placeholder{
    font-size: 16px;
  }

  .password-register-button{
    font-size: 14px;
  }

  .password-back-to-login-link{
    font-size: 12px;
  }

  .checkyouremail-img{
    width: 63px;
    height: 50px;
    margin-bottom: 20px;
  }

  .main-text-1{
    margin-bottom: 10px;
  }

  .sub-text-1{
    font-size: 12px;
  }

  .password-resend-link{
    font-size: 13px;
  }
}
