.feature-tick-green{
  color: #04d755;
  font-size: 13px;
}

.feature-cross-red{
  color: red;
  font-size: 13px;
}

.plan-card-div{
  /* height: 380px; */
  width: 285px;
}

.plan-card-icon{
  width: 62px;
  height: 62px;
  margin-left: 16px;
}

.plan-card-title{
  font-size: 18px;
  color: #693293;
  margin-right: 16px;
}

.plan-card-price{
  font-size: 25px;
  color: #000;
  margin-top: -5px;
  margin-right: 16px;
}

.per-user{
  font-size: 12px;
  color: #797676;
}

.plan-card-feature-text{
  font-size: 12px;
  color: #693293;
  font-weight: 600;
  margin-bottom: 5px;
}

.custom-card-text{
  font-size: 14px;
  color: #693293;
  font-weight: 400;
  line-height: 207%;
  text-justify: auto;
  margin-top: 34px;
  margin-bottom: 50px;
  padding: 0px 15px;
  text-align: center;
}

/* Update your existing CSS file or add this to your styles */
.plan-card-link-button {
  display: inline-block;
  padding: 4px 32px;
  width: 226px;
  text-align: center;
  background-color: #FFFFFF; /* Choose your desired button color */
  color: #000000;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  border: 1px solid;
  font-size: 14px;
  margin-bottom: 14px;

  /* Optional: Add hover effect */
  &:hover {
    background-color: #F4E869; /* Choose a slightly darker shade for hover effect */
    text-decoration: none;
    color: #000000;
    /* border: 1px solid #F4E869; */
  }

  &:focus{
    background-color: #F4E869;
    text-decoration: none;
    color: #000000;
    outline: none;
    border: none;
  }
}


.plan-card-button{
  display: inline-block;
  padding: 4px 32px;
  width: 226px;
  text-align: center;
  background-color: #FFFFFF; /* Choose your desired button color */
  color: #000000;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Added color transition */
  border: 1px solid;
  font-size: 14px;
  cursor: pointer; /* Add cursor style for indicating it's clickable */
  margin-bottom: 14px;

  /* Optional: Add hover effect */
  &:hover {
    background-color: #F4E869; /* Choose a slightly darker shade for hover effect */
    text-decoration: none;
    color: #000000;
  }

  &:focus{
    background-color: #F4E869;
    text-decoration: none;
    color: #000000;
    outline: none;
    border: none;
  }
  
}

.discount-text{
  font-size: 9px;
  color: #26AC2C;
  font-weight: medium;
  padding: 1px 4px;
  background-color:#BEE7C0;
  border-radius: 3px; 
  margin-left: 4px;
}

.strike-price{
  text-align: left;
  font-size: 12px;
  color: #797676;
  text-decoration: line-through;
  margin-top: -4px;
}