.days-button-prod-out-of-stock {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin-bottom: 10px;
}

.prem-day-0-out-of-stock, .prem-day-7-out-of-stock, .prem-day-15-out-of-stock, .prem-day-30-out-of-stock {
  /* Add your button styling here */
  margin-left: 8px; /* Add margin between buttons */
  background-color: #693293;
  border-radius: 5px;
  font-size: 10px;
  padding: 4px 15px;
}

.days-button-active{
  background-color: #693293;
  color: #FFFFFF;
}

.days-button-inactive{
  background-color: #CECDCD;
  color: #727070;
}


