.my-profile-page {
  background-color: #f1f5fe;
}

.my-profile-box {
  background-color: #ffffff;
}

@media (max-width: 1279px) {
  .my-profile-back-home {
    color: #693293;
    font-size: 13px;
    font-weight: 500;
  }
  .my-profile-photo {
    width: 152px;
    height: 152px;
  }

  .my-profile-back-home:hover {
    color: #693293;
    text-decoration: none;
  }

  .my-profile-personal-details-cards {
    width: 409px;
    border-width: 1px;
    border-style: solid;
    border-color: #cacaca;
    border-radius: 5px;
  }
  .my-profile-personal-details-heading {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
  }
  .my-profile-header {
    font-size: 12px;
    color: #505050;
    font-weight: 500;
  }

  .my-profile-information {
    font-size: 12px;
    color: #505050;
    font-weight: 400;
  }
  .my-profile-change-plan-button {
    background-color: #693293;
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    width: 121px;
  }

  .my-profile-change-plan-button:hover,
  .my-profile-change-plan-button:focus {
    background-color: #f4e869;
    color: #303030;
  }
  .my-profile-edit-details-button {
    background-color: #693293;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    width: 140px;
  }

  .my-profile-edit-details-button:hover,
  .my-profile-edit-details-button:focus {
    background-color: #f4e869;
    color: #303030;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1919px) {
  .my-profile-back-home {
    color: #693293;
    font-size: 13px;
    font-weight: 500;
  }
  .my-profile-photo {
    width: 152px;
    height: 152px;
  }

  .my-profile-back-home:hover {
    color: #693293;
    text-decoration: none;
  }
  .my-profile-personal-details-cards {
    width: 409px;
    border-width: 1px;
    border-style: solid;
    border-color: #cacaca;
    border-radius: 5px;
  }
  .my-profile-personal-details-heading {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
  }
  .my-profile-header {
    font-size: 12px;
    color: #505050;
    font-weight: 500;
  }

  .my-profile-information {
    font-size: 12px;
    color: #505050;
    font-weight: 400;
  }
  .my-profile-change-plan-button {
    background-color: #693293;
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    width: 121px;
  }

  .my-profile-change-plan-button:hover,
  .my-profile-change-plan-button:focus {
    background-color: #f4e869;
    color: #303030;
  }
  .my-profile-edit-details-button {
    background-color: #693293;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    width: 140px;
  }

  .my-profile-edit-details-button:hover,
  .my-profile-edit-details-button:focus {
    background-color: #f4e869;
    color: #303030;
  }
}

/* Styles for desktop screens */
@media (min-width: 1920px) {
  .my-profile-back-home {
    color: #693293;
    font-size: 18px;
    font-weight: 500;
  }
  .my-profile-photo {
    width: 228px;
    height: 228px;
  }

  .my-profile-back-home:hover {
    color: #693293;
    text-decoration: none;
  }
  .my-profile-personal-details-cards {
    width: 613px;
    border-width: 1px;
    border-style: solid;
    border-color: #cacaca;
    border-radius: 5px;
  }
  .my-profile-personal-details-heading {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
  .my-profile-header {
    font-size: 18px;
    color: #505050;
    font-weight: 500;
  }

  .my-profile-information {
    font-size: 18px;
    color: #505050;
    font-weight: 400;
  }
  .my-profile-change-plan-button {
    background-color: #693293;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    width: 181px;
  }

  .my-profile-change-plan-button:hover,
  .my-profile-change-plan-button:focus {
    background-color: #f4e869;
    color: #303030;
  }
  .my-profile-edit-details-button {
    background-color: #693293;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    width: 210px;
  }

  .my-profile-edit-details-button:hover,
  .my-profile-edit-details-button:focus {
    background-color: #f4e869;
    color: #303030;
  }
}
