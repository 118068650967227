.forgot-password{
  font-size: 14px;
  color:#4B4389;
  font-weight: 400;
  margin-bottom: 30px;
}

.forgot-password:hover{
  color: #5135EE;
  text-decoration: none;
}

.create-password-input {
  border: none;
  border-bottom: 1px solid #d8d8d8;
  /* padding-bottom: 0px; */
  padding: 0px;
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace; /* Set your desired font size for the password input */
}
.create-password-input::placeholder {
  font-size: 14px; /* Set your desired placeholder text size */
  font-family: 'Montserrat', sans-serif;
}

.right-side-login{
  background-color: #693293;
}

.login-right-side-heading{
color: #FFFFFF;
font-size: 28px;
font-weight: 500;
font-family: 'Montserrat', sans-serif;
margin-bottom: 44px;
}

.login-right-side-footer{
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.bg-img-1{
  width: 538px;
  height: 383px;
  margin-bottom: 44px;
}

.no-account{
  font-size: 12px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}


.login-link{
  color: #693293;
  text-decoration: none;
  font-size: 16px;
}

.login-button{
    background-color: #693293;
    font-weight: 400;
    color: #FFF;
  }

.login-button:hover, 
.login-button:focus{
  background-color: #F4E869;
  color: #000;
}

@media screen and (max-width: 480px) {
  /* Hide right area */
  .right-side-login {
    display: none;
  }

  /* Adjust left area to take up full width */
  .left-area {
    width: 100%;
    padding: 20px 0px; /* Add some padding for better spacing */
  }

  .second-div-wrapper{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .color-logo-register{
    margin-right: 44px;
  }
}

