body.with-background {
  background-color: #F1F5FE;
  background-size: cover !important;
  /* Additional background properties (e.g., background-repeat, background-position) can be added here */
}

.landing-page-button {
  background-color: #693293;
  font-weight: 500;
  color: white;
}

.landing-page-button:hover,
.landing-page-button:focus {
  background-color: #f4e869;
  color: #000000;
}

.landing-page-special-text {
  color: #693293;
}

/* Styles for smaller screens (mobile devices) */
@media (max-width: 1279px) {
  .landing-page-box {
    width: 100%;
    flex-direction: column;
    height: auto;
  }

  .landing-page-header-text {
    font-size: 30px;
    color: #000000;
    font-weight: 500;
    letter-spacing: 0.01rem;
  }

  .landing-page-title-text {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin-top: 24px;
  }

  .landing-page-sub-text {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    margin-top: 20px;
  }

  .landing-page-button-1 {
    width: 203px;
    height: 34px;
    border-radius: 5px;
    font-size: 14px;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1535px) {
  .landing-page-box {
    width: 1152px;
    height: auto;
  }

  .landing-page-header-text {
    font-size: 30px;
    color: #000000;
    font-weight: 500;
    letter-spacing: 0.01rem;
  }

  .landing-page-title-text {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin-top: 24px;
  }

  .landing-page-sub-text {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    margin-top: 20px;
  }

  .landing-page-button-1 {
    width: 203px;
    height: 34px;
    border-radius: 5px;
    font-size: 14px;
  }
}

/* Styles for desktop screens */
@media (min-width: 1536px) {
  .landing-page-box {
    width: 1741px;
    height: auto;
  }

  .landing-page-header-text {
    font-size: 45px;
    color: #000000;
    font-weight: 500;
    letter-spacing: 0.01rem;
  }

  .landing-page-title-text {
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    margin-top: 24px;
  }

  .landing-page-sub-text {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin-top: 20px;
  }

  .landing-page-button-1 {
    width: 228px;
    height: 54px;
    border-radius: 8px;
    font-size: 21px;
  }
}

@media (min-width: 360px) and (max-width: 480px){
.right-div-wrapper{
  padding: 10px;
}

.left-div-wrapper{
  padding: 10px;
}

.landing-page-sub-text{
  margin-bottom: 10px;
}

.landing-page-button{
  font-size: 12px;
  width: 150px;
  padding: 4px 4px;
}

.paid-button-wrapper{
  margin-right: 1px;
}

.custom-view{
  justify-content: center;

}
}
