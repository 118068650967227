.buy-more-button{
  background-color: #693293;
  padding: 4px 8px;
  margin-right: 80px;
  margin-top: 36px;
  margin-bottom: 18px;
  border-radius: 5px;
  font-size: 14px;
  color: #FFFFFF;
}

.buy-more-button:hover, .buy-more-button:focus{
  color: #000000;
  text-decoration: none;
  background-color: #F4E869;
  transition: .1s ease-in;
}

/* Ensure the table container is scrollable on smaller screens */
.table-container {
  overflow-y: auto;
}

@media (min-width: 360px) and (max-width: 480px){
  .buy-more-button{
    margin-right: 10px;
  }
}