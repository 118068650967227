.pay-fail-box{
  width: 325px;
  /* border: 1px solid; */
  height: 300px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.pay-icon{
  width: 75px;
  height: 75px;
  margin-bottom: 18px;
}

.payment-fail-success-heading{
  font-size: 22px;
  font-weight: 500;
  color: #474747;
  margin-bottom: 24px;
}

.payment-id-label{
  font-size: 14px;
  margin-left: 20px;
}

.payment-id{
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
}

.payment-fail-success-subtext{
  font-size: 12px;
  font-weight: 400;
  color: #707070;
  margin-bottom: 34px;
}

.payment-fail-retry-button{
  font-size: 12px;
  font-weight: 400;
  background-color: #693293;
  border-radius: 5px;
  width: 282px;
  color: #FFFFFF;
  /* margin-bottom: 29px; */
}

.payment-fail-retry-button:hover, .payment-success-start-button:focus, .payment-success-start-button:hover, .payment-fail-retry-button:focus{
  background-color: #F4E869;
  color: #000000;
}


.pay-success-box{
  width: 325px;
  height: 300px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.payment-success-start-button{
  font-size: 12px;
  font-weight: 400;
  background-color: #693293;
  border-radius: 5px;
  width: 282px;
  color: #FFFFFF;
}

.payment-success-heading{
  font-size: 22px;
  font-weight: 500;
  color: #474747;
  margin-bottom: 23px;
}

.payment-success-hr {
  width: 90%;
  height: .3px;
  border: none;
  background-color: #ccc; /* Choose the color that fits your design */
  margin-bottom: 28px; /* Adjust the margin as needed */
}

.payment-info-table{
margin-bottom: 28px;
}

.payment-info-table {
  width: 100%;
  border-collapse: collapse;
}

.payment-info-table td {
  padding: 2px 8px; /* Adjust the value as needed for the desired gap */
  font-size: 12px;
}

/* Optionally, you can also add some styling for better readability */
.payment-info-table td:nth-child(2) {
  font-weight: 500;
}