.page {
  background-color: #f1f5fe;
}

.new-password-register-input-field {
  background-color: #f1f5fe;
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
  border: none;
  color: #454545;
  border-bottom: 1px solid #d8d8d8;
  padding: 0px;
}

.new-password-register-input-field::placeholder{
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.new-password-register-input-field:focus {
  border: none;
  outline: none; /* Remove outline on focus */
  border-bottom: 1px solid #797676; /* Change the focused border color */
}

.new-password-register-button {
  background-color: #693293;
  font-weight: 400;
  color: #fff;
}
.new-password-register-button:hover,
.new-password-register-button:focus {
  background-color: #f4e869;
  color: #303030;
}

@media (max-width: 1279px) {
  .logo-style {
    width: 144px;
    height: 40px;
  }
  .new-password-img-style {
    width: 91px;
    height: 92px;
  }
  .new-password-heading {
    font-size: 24px;
    color: #000000;
  }

  .new-password-note {
    font-size: 12px;
    color: #303030;
  }

  .new-password-register-input-field-1 {
    font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
  }

  .new-password-register-input-field-1::placeholder{
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
  }

  .new-password-register-button-1 {
    font-size: 12px;
    border-radius: 5px;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1919px) {
  .logo-style {
    width: 144px;
    height: 40px;
  }
  .new-password-img-style {
    width: 91px;
    height: 92px;
  }
  .new-password-heading {
    font-size: 24px;
    color: #000000;
  }

  .new-password-note {
    font-size: 12px;
    color: #303030;
  }
  .new-password-register-input-field-1 {
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace; /* Set your desired font size for the password input */
  }

  .new-password-register-input-field-1::placeholder{
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  }
  .new-password-register-button-1 {
    font-size: 12px;
    border-radius: 5px;
  }
}

/* Styles for desktop screens */
@media (min-width: 1920px) {
  .logo-style {
    width: 210px;
    height: 66px;
  }
  .new-password-img-style {
    width: 136.5px;
    height: 138px;
  }
  .new-password-heading {
    font-size: 36px;
    color: #000000;
  }

  .new-password-note {
    font-size: 18px;
    color: #303030;
  }
  .new-password-register-input-field-1 {
    font-size: 18px;
  font-family: 'Courier New', Courier, monospace;
  }
  .new-password-register-button-1::placeholder {
    font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  }
  .new-password-register-button-1 {
    font-size: 18px;
    border-radius: 8px;
  }

  
}

@media (min-width: 360px) and (max-width: 480px){
 .new-password-img-style{
  width: 72px;
  height: 70px;
  margin-bottom: 10px;
 } 

 .new-password-note{
  font-size: 13px;
  margin-bottom: 10px;
 }

 .new-password-register-input-field{
  font-size: 14px;
 }

 .new-password-register-input-field::placeholder{
  font-size: 13px;
 }

 .new-password-register-button{
  margin-top: 20px;
 }
}
