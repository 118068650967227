body.with-background {
  background-color: #F1F5FE;
  background-size: cover !important;
  /* Additional background properties (e.g., background-repeat, background-position) can be added here */
}

.contact-text-logo {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}

.contact-text-logo:hover {
  color: #f4e869;
}

.contact-about-link, .contact-contact-link {
  color: #000000;
}

.contact-about-link:hover, .contact-contact-link:hover {
  text-decoration: none;
  color: #f4e869;
}

.contact-main-div {
  width: 1050px;
  height: fit-content;
  border-radius: 10px;
  display: flex;
}

.contact-column-1 {
  width: inherit;
  background-color: #FFFFFF;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 81px;
  padding-right: 81px;
}

.contact-column-2 {
  width: 394px;
  background-color: #693293;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.contact-contact-us-heading {
  font-size: 30px;
  color: #000000;
  font-weight: 600;
  margin-top: 48px;
}

.contact-contact-us-sub-text {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-top: 14px;
}

.contact-input-field {
  border: none;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
  padding: 0px;
}

.contact-input-field:focus {
  border: none;
  outline: none; /* Remove outline on focus */
  border-bottom: 1px solid #693293; /* Change the focused border color */
}

.contact-input-field::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #454545;
}

.contact-button {
  background-color: #693293;
  font-weight: 400;
  width: 223px;
  margin-top: 34px;
  color: #FFFFFF;
}

.contact-button:hover, 
.contact-button:focus {
  background-color: #f4e869;
  color: #303030;
}

.contact-address-heading-1 {
  font-size: 30px;
  margin-top: 48px;
  font-weight: 600;
  color: #E6E6E6;
}

.contact-address-heading-2 {
  font-weight: 500;
  font-size: 11px;
  color: #E6E6E6;
}

.contact-heading-sub-text {
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 400;
}

@media (min-width: 360px) and (max-width: 480px) {
  .contact-main-div {
    flex-direction: column; /* Stack columns vertically */
    width: 100%; /* Make sure it fits the screen width */
  }

  .contact-column-1, .contact-column-2 {
    width: 100%; /* Make both columns take full width */
    border-radius: 0; /* Reset border radius for mobile */
    padding-left: 16px; /* Adjust padding for smaller screen */
    padding-right: 16px; /* Adjust padding for smaller screen */
  }

  .contact-column-1 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 16px; /* Add some padding to the top */
    padding-bottom: 16px; /* Add some padding to the bottom */
  }

  .contact-column-2 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 16px; /* Add some padding to the top */
    padding-bottom: 16px; /* Add some padding to the bottom */
    margin-top: 16px; /* Add some space between the columns */
  }

  .contact-input-field {
    padding: 8px; /* Adjust input padding for mobile */
  }

  .contact-button {
    width: 100%; /* Make button take full width */
  }

  .contact-us-wrapper{
    margin-top: 10px;
  }

  .contact-contact-us-heading{
    margin-top: 10px;
  }

  .input-wrapper{
    margin-top: 20px;
  }

  .contact-address-heading-1{
    margin-top: 10px;
  }
}
