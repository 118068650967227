.active-subscription{
  color: #26AC2C;;
}

.inactive-subscription{
  color: #FB0D0D;
}

.table-top-wrapper{
  margin: 0px 80px;
}



@media (min-width: 360px) and (max-width: 480px){
  .table-top-wrapper{
    margin: 0px 10px;
    min-width: max-content;
  }
}