/* Add these styles to your CSS file */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader-overlay {
  background: rgba(255, 255, 255, 0.7); /* Adjust the transparency here */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001; /* Place it above the loader content */
}

.loader-content {
  position: relative;
  z-index: 1002; /* Place it above the overlay */
}
