.about-us-navbar, .contact-us-navbar{
  font-size: 18px;
  color: #4B4389;
  }
  
  .about-us-navbar:hover, .contact-us-navbar:hover{
  color: #E56755;
  }
  
  .bg-all{
    background-color: #F1F5FE;
  }

  body {
    font-family: 'Montserrat', sans-serif;
  }
  
  .navbar-logo{
    height: 30px;
    width: 30px;
  }
  
  .navbar-logo-text{
    color: #4B4389;
    font-size: 22px;
  }
  .navbar-logo-text:hover, .navbar-logo-text:focus{
    color: #4B4389;
  }
  

  .white-ham{
    color: #FFFFFF;
  }

  .blacl-ham{
    color: black;
  }

  .disabled-icon{
    opacity: 60% !important;
  }

  body.with-background {
    background-color:#F1F5FE;
    background-size: cover !important;
    /* Additional background properties (e.g., background-repeat, background-position) can be added here */
  }