.graph-wrapper-div{
  border: 1px solid #DAD6F5;
  border-radius: 8px;
}

.right-side-detail-div{
  width: 191px;
  border-radius: 8px;
  border: 1px solid #DAD6F5;
}

.selling-price-details{
  color: #000000;
  font-size: 11px;
  font-weight: 500;
}

.selling-price-sub-details{
  font-size: 11px;
  font-weight: 500;
  color: #5D5D5D;
}

.highest-price-div{
  border-color: #793FDF;
}

.average-price-div{
  border-color: #1CC5DC;
}

.lowest-price-div{
  border-color: #F7EA00;
}