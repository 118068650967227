.platform-text{
  font-size: 15px;
  padding: 8px 12px;
  width:max-content;
  justify-content: center;
  color: black;
  text-transform: capitalize;
  }
  
  .platform-main-div{
    /* border-right: 1px solid rgba(128, 128, 128, 0.5); */
    border-left: 1px solid rgba(128, 128, 128, 0.5);
  }
  
  .platform-checkbox{
    height: 16px;
    width: 16px;
    border-radius: 2px;
  }
  