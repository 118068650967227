.navbar-logo{
  width: 40px;
  height: 30px;
}

.logo-text{
  font-family: 'Montserrat';
  font-weight: 500;
  color: #023664;
  font-size: 16px;
}

.user-name{
  font-family: 'Montserrat';
  font-weight: 500;
  color: #000;
  font-size: 15px;
  margin-left: 8px;
}

.user-name-white{
  font-family: 'Montserrat';
  font-weight: 500;
  color: #FFFFFF;
  font-size: 15px;
  margin-left: 8px;
}

.link-style-navbar {
  color: inherit;
  text-decoration: none;
}

.link-style-navbar:hover {
  color: inherit;
  text-decoration: none;
}

.nav-bar-logo{
  height: 44px;
  width: 144px;
}

.mobile-filter-icon{
  display: none;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

@media (min-width: 360px) and (max-width: 480px) {
  .nav-wrapper {
    padding-left: 4px;
    padding-right: 2px;
  }
  
  .nav-bar-logo {
    /* display: none;  */
    width: 82px;
    height: 24px;
  }

  .mobile-filter-icon{
    display: inline;
    font-size: 24px;
    /* color: #ffffff; */
    cursor: pointer;
  }

  
}


