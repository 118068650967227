.sidebar-area{
  background-color: #693293;
}

.filter-bg{
  /* background-color: #7B49A3; */
  /* background-color: #8453AD; */
  /* background-color: #8D66B8; */
  background-color: #58247F;
  /* background-color: #4E2075; */
  /* background-color: #D6CC93; */
}

.product-name-filter{
  color: #ffe490;
}

.filter-done-button:hover{
/* background-color: ; */
}


/* .card-div{
  width: 218px;
  height: 88px;
  border-radius: 0.5rem;
box-shadow: 1.6px 1.6px 15.99995px 0px rgba(0, 0, 0, 0.15);
} */

.text-reports, .text-filters{
font-size: 13px;
font-weight: 500;
color: #ffffff;
}

.sidebar-logo{
width: 140px;
height: 44px;
}

.product-side-icon{
  width: 20px;
  height: 20px;
}

.selected-product-report{
  color: #FFE490;
  font-size: 12px;
  font-weight: 500;
}

.unselected-product-report{
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
}

.disabled-product-report{
  color: #969696;
  /* opacity: 50%; */
  cursor: not-allowed;
  font-size: 12px;
  font-weight: 500;
}

.filter-dropdown-text{
  font-size: 12px;
}


.button-done, .button-reset{
  color: #FFFFFF;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
}

.button-done:hover, .button-done:active{
  background-color: #F4E869;
  color: #000000;
}

.button-reset:hover, .button-reset:active{
  background-color: #FFFFFF;
  color: #000000;
}

.button-done.clicked {
  background-color: #C9C035;
  color: #000000;
}

.button-reset.clicked {
  background-color: #CCCCCC;
  color: #000000;
}



@media (min-width: 360px) and (max-width: 480px) {
  .sidebar-area{
    margin-top: 40px;
  }
}
