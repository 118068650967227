.otp-verification-page {
  background-color: #f1f5fe;
}
.otp-box {
  letter-spacing: 0.5em;
  font-size: 30px;
  border: 1px solid #cecdcd;
  background-color: #f1f5fe;
  height: 40px;
}

.otp-verification-button {
  background-color: #693293;
  font-weight: 500;
  color: #fff;
}
.otp-verification-button:hover,
.otp-verification-button:focus {
  background-color: #f4e869;
  color: #303030;
}

/* Add this CSS to your stylesheet */
.disabled-button {
  background-color: #e0e0e0; /* Change the background color */
  color: #9e9e9e; /* Change the text color */
  cursor: not-allowed; /* Change the cursor */
  /* Add any other styles you want to apply when the button is disabled */
}


@media (max-width: 1279px) {
  .logo-style {
    width: 144px;
    height: 40px;
  }
  .otp-verification-img {
    width: 80px;
    height: 80px;
  }
  .otp-verification-heading {
    font-size: 24px;
    color: #000000;
    margin-top: 58px;
  }

  .otp-verification-note {
    font-size: 12px;
    margin-top: 8px;
    color: #303030;
  }
  .otp-box-1 {
    height: 40px;
    margin-top: 38px;
    border-radius: 5px;
  }

  .otp-verification-button-1 {
    font-size: 12px;
    width: 270px;
    height: 34px;
    border-radius: 5px;
    margin-top: 28px;
  }
  .otp-verification-timer {
    margin-top: 18px;
    font-weight: 400;
    font-size: 12px;
  }
  .otp-verification-timer-text {
    font-size: 13px;
    font-weight: 600;
  }
}

/* Styles for tablets and small laptops */
@media (min-width: 1280px) and (max-width: 1919px) {
  .logo-style {
    width: 144px;
    height: 40px;
  }
  .otp-verification-img {
    width: 80px;
    height: 80px;
  }
  .otp-verification-heading {
    font-size: 24px;
    color: #000000;
    margin-top: 58px;
  }

  .otp-verification-note {
    font-size: 12px;
    margin-top: 8px;
    color: #303030;
  }
  .otp-box-1 {
    height: 40px;
    margin-top: 38px;
    border-radius: 5px;
  }

  .otp-verification-button-1 {
    font-size: 12px;
    width: 270px;
    height: 34px;
    border-radius: 5px;
    margin-top: 28px;
  }
  .otp-verification-timer {
    margin-top: 18px;
    font-weight: 400;
    font-size: 12px;
  }
  .otp-verification-timer-text {
    font-size: 13px;
    font-weight: 600;
  }
}

/* Styles for desktop screens */
@media (min-width: 1920px) {
  .logo-style {
    width: 210px;
    height: 66px;
  }
  .otp-verification-img {
    width: 120px;
    height: 120px;
  }
  .otp-verification-heading {
    font-size: 36px;
    margin-top: 51px;
    color: #000000;
  }

  .otp-verification-note {
    font-size: 18px;
    margin-top: 13px;
    color: #303030;
  }
  .otp-box-1 {
    height: 48px;
    margin-top: 57px;
    border-radius: 8px;
  }

  .otp-verification-button-1 {
    font-size: 18px;
    width: 434px;
    height: 54px;
    border-radius: 8px;
    margin-top: 42px;
  }
  .otp-verification-timer {
    margin-top: 27px;
    font-weight: 400;
    font-size: 18px;
  }
  .otp-verification-timer-text {
    font-size: 20px;
    font-weight: 600;
  }
}
