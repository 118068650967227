.user-profile-main-content-div {
  width: 712px;
  height: 559px;

  /* border: 1px solid #000; */
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.profile-back-home {
  color: #693293;
  font-size: 13px;
  font-weight: 500;
}

.profile-back-home:hover {
  color: #693293;
  text-decoration: none;
}

.profile-pic-placeholder {
  width: 150px;
  height: 150px;
  margin-bottom: 23px;
}

.update-profile-pic {
  font-size: 12px;
  font-weight: 400;
  color: #0585fb;
  text-decoration: underline;
  cursor: pointer;
}

.update-profile-pic:hover {
  color: #4b4389;
}

.input-fields-div {
  width: 100%;
}

.profile-input {
  font-size: 12px;
  border-color: #aeaeae;
  width: 270px;
  height: 35px;
  background-color: #ffffff;
}

.profile-input-email {
  font-size: 12px;
  border-color: #aeaeae;
  width: 270px;
  height: 35px;
  background-color: #d9d9d9;
}

.profile-button-save {
  background-color: #4b4389;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}

.profile-button-save:hover,
.profile-button-save:focus {
  background-color: #e56755;
}

.profile-button-cancel {
  background-color: #efefef;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  /* margin-left: 10px; */
}

.profile-button-cancel:hover,
.profile-button-cancel:focus {
  background-color: #e56755;
  color: #ffffff;
}


/* --------- MODAL------- */

.profile-modal-open {
  display: flex; /* or other appropriate styles to show the modal */
}

/* CSS */
.profile-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 100; /* Ensure it's above other content */
  justify-content: center;
  align-items: center;
  display: flex;
}

.profile-modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.profile-upload-icon {
  font-size: 50px;
  margin-top: 10px;
  color: #4b4389;
}

.profile-upload-text {
  margin-top: 20px;
  color: #828282;
  font-size: 20px;
}

.profile-select-button {
  background-color: #4b4389;
  color: #ffffff;
  font-size: 14px;
  margin-top: 20px;
  /* border-radius: 8px; */
  border: none;
}

#file-upload-button {
  border: none;
}

.profile-upload-button {
  background-color: #4b4389;
  color: #ffffff;
  font-size: 14px;
  margin-top: 40px;
}

.profile-upload-button:disabled {
  background-color: #828282;
  cursor: not-allowed;
}

.profile-select-button:hover {
  background-color: #e56755;
}
.profile-upload-button:hover:not(:disabled),
.profile-upload-button:focus:not(:disabled) {
  background-color: #e56755;
}

.profile-modal-close-icon {
  font-size: xx-large;
  color: #d77e7e;
}

.profile-modal-close-icon:hover {
  cursor: pointer;
}

.profile-password {
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace; /* Set your desired font size for the password input */
}

.profile-password::placeholder {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 360px) and (max-width: 480px){
  .navbar-profile-pic{
    width: 16px;
    height: 16px;
  }
}
